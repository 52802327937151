/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-indent */
/* eslint-disable import/order */
import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { navigate } from "gatsby";
import * as rdd from 'react-device-detect';
import TrustPilot from '../widgets/TrustPilot';
import { ImageGallery } from '../../ui/imageGallery';
import { Image } from '../../ui/image';
import pages from '../../../utils/pages';

function getRandomUrl() {
  const components = ['ssu', 'ssu_with_video'];
  const randomIndex = Math.floor(Math.random() * components.length);
  return components[randomIndex];
}

const openSSU = (page_url) => {
  console.log('page_url',page_url);
  navigate(page_url);
  return false;
}


const { getPageUrl } = pages;
const Signups: FC<{ lang: string; translation: TFunction }> = ({
  lang, 
  translation,
}) => {
  const methods = translation('methods', {
    returnObjects: true,
  });



  return (
    <section className="section-login is-vcentered ">
      <div className="section-login--container card-container container">

        <div className="card-container--main">
        <Image
            name="shore_logo.svg"
            className="logo-svg"
            alt="shore.com"
        />
          <h1
            className="card-container--title title"
            dangerouslySetInnerHTML={{
              __html: `${translation('headline')}`,
            }}
          />
          <div className="card-container--wrapper">
            {Object.keys(methods).map((key) => {
             
              const {
                textBold,
                text,
                link,
                classN,
                btnId,
              } = methods[key];
              let page;

              if(link === 'ssu' && lang === 'de') page = getPageUrl(getRandomUrl() , lang);
              else page = getPageUrl(link, lang);
              

              if (page) {
                const url = page.url ? page.url : '';
                return (
                  <a
                    className={`card-container--component card-component ${classN}`}
                    data-testid={btnId}
                    key={key+url}
                    onClick={() => openSSU(url)}
                  >
                    <div className="card-component--wrapper">
                      <div className="card-component--container">
                        <h2 className="card-component--title">{textBold}</h2>
                        <p className="card-component--text">{text}</p>
                      </div>
                      <div className="card-component--img">
                        <Image
                          name="arrow-right.svg"
                          className={`${classN} inline-svg inline-icon`}
                          alt="arrow icon"
                        />
                      </div>
                    </div>
                  </a>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>

      {rdd.isDesktop && (
        <div className="more-info is-hidden-touch">
          <div className="more-info--container is-vcentered">
          
            <ImageGallery
                  name={translation('image')}  
                  className="signup_image"
                  alt="trust image made in germany"
                  hero
            />
            <div className="trust">
              <div className="trust-card">
                <TrustPilot />
              </div>
              <div className="trust-card">
                <ImageGallery
                  width="474"
                  height="324"
                  name="shore_trust_seals_01_made_in_germany.png"
                  className="trust-img"
                  alt="trust image made in germany"
                  hero
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export { Signups };
